import { Heading as CarbonHeading } from '@carbon/react';
import PropTypes from 'prop-types';
import React from 'react';

const Heading = ({
    size,
    color,
    className,
    style,
    children,
    ...rest
}) => {
    const colorCheck = color ? { color: `var(--cds-spark-theme-text-${color})` } : null;

    return (
        <CarbonHeading
            className={`${size ? 'spark-heading-size-' + size : ''} ${className}`}
            style={{
                ...colorCheck,
                ...style
            }}
            {...rest}
        >
            {children}
        </CarbonHeading>
    );
};

Heading.propTypes = {
    /**
     * Specifies the heading size and associated typography properties such as letter spacing.
     */
    size: PropTypes.oneOf(['2xl', 'xl', 'l', 'm', 'ms', 's', 'xs']),
    /**
     * Specify the content that will be placed in the component
     */
    children: PropTypes.node.isRequired,
    /**
     * Specify a class name for the outermost node of the component
     */
    className: PropTypes.string,
    /**
     * Applies inline styles to the heading.
     */
    style: PropTypes.object,
    /**
     * Specify heading color
     */
    color: PropTypes.oneOf(['primary', 'secondary', 'disabled', 'placeholder', 'inverse'])
};
export default Heading