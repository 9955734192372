import {
    Column,
    FormLabel,
    Grid,
    Link,
    ListItem,
    Tag,
    UnorderedList,
} from '@carbon/react';

import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../common/Header';
import { HeroBanner } from '../common/HeroBanner';
import Card from './Card';
import './Collections.scss';
import Heading from './Heading';
import Text from './Text';

export const Collections = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [viewOption, setViewOption] = useState('grid');
    const getLinkStyle = (hash) => {
        return location.hash === hash ||
            (location.hash === '' && hash === '#overview')
            ? {
                  borderBottom: '2px #16CFB1 solid',
                  color: '#161616',
                  fontSize: '14px',
                  fontFamily: 'IntelOne Text',
                  fontWeight: '500',
                  lineHeight: '18px',
                  letterSpacing: '0.16px',
                  wordWrap: 'break-word',
              }
            : {
                  borderBottom: '2px #C6C6C6 solid',
                  color: '#525252',
                  fontSize: '14px',
                  fontFamily: 'IntelOne Text',
                  fontWeight: '400',
                  lineHeight: '18px',
                  letterSpacing: '0.16px',
                  wordWrap: 'break-word',
              };
    };
    const Cards = [
        {
            heading: 'Metro AI Suite Software Development Kit',
            icon: 'document-code',
            tags: [
                {
                    text: 'Software Development Kit',
                    type: 'gray',
                },
                {
                    text: 'AI and Machine Learning',
                    type: 'gray',
                },
                {
                    type: 'gray',
                    text: 'Safety and Security',
                },
                { 
                    type: 'gray', 
                    text: 'Transportation' 
                },
            ],
            children: (
                <>
                    <Text style={{ color: '#161616' }}>
                        A comprehensive and modular toolkit for accelerated
                        media processing and AI inference, designed to
                        fast-track the development of visual AI solutions.
                    </Text>
                    <Link
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            height: '32px',
                            alignItems: 'flex-end',
                        }}
                        href='https://edgesoftwarecatalog.intel.com/details/?microserviceType=recipe&microserviceNameForUrl=metro-ai-suite-sdk-for-software-developers'
                        target='_blank'
                    >
                        <i
                            className="spark-icon spark-icon-arrow-large-right spark-icon-regular"
                            style={{
                                fontFamily: 'spark-icon',
                                fontSize: '20px',
                            }}
                        />
                    </Link>
                </>
            ),
        },
        {
            heading: 'Metro AI Suite Device Qualification',
            icon: 'check-circle',
            tags: [
                {
                    text: 'Technology Evaluation Kit',
                    type: 'gray',
                },
                {
                    text: 'Platform Analysis, Tuning, and Monitoring',
                    type: 'gray',
                },
                {
                    type: 'gray',
                    text: 'Safety and Security',
                },
                { 
                    type: 'gray', 
                    text: 'Transportation' 
                },
            ],
            children: (
                <>
                    <Text style={{ color: '#161616' }}>
                        Provides media, AI, and end-to-end pipeline benchmark
                        tools for partners to capture video analytics
                        performance and co-market with Intel.
                    </Text>
                    <Link
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            height: '32px',
                            alignItems: 'flex-end',
                        }}
                        href='https://edgesoftwarecatalog.intel.com/details/?microserviceType=recipe&microserviceNameForUrl=metro-ai-suite-device-qualification-for-hardware-builder'
                        target='_blank'
                    >
                        <i
                            className="spark-icon spark-icon-arrow-large-right spark-icon-regular"
                            style={{
                                fontFamily: 'spark-icon',
                                fontSize: '20px',
                            }}
                        />
                    </Link>
                </>
            ),
        },
        {
            heading: 'Sensor Fusion for Traffic Management',
            icon: 'build-cube',
            tags: [
                {
                    text: 'Reference Implementation',
                    type: 'gray',
                },
                {
                    text: 'AI and Machine Learning',
                    type: 'gray',
                },
                {
                    type: 'gray',
                    text: 'Safety and Security',
                },
                { 
                    type: 'gray', 
                    text: 'Transportation' 
                },
            ],
            children: (
                <>
                    <Text style={{ color: '#161616' }}>
                        A multi-modal reference implementation for traffic
                        management, enabling partners to blend camera and radar
                        sensor inputs to accurately monitor traffic conditions.
                    </Text>
                    <Link
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            height: '32px',
                            alignItems: 'flex-end',
                        }}
                        href='https://edgesoftwarecatalog.intel.com/details/?microserviceType=recipe&microserviceNameForUrl=metro-ai-suite-sensor-fusion-for-traffic-management'
                        target='_blank'
                    >
                        <i
                            className="spark-icon spark-icon-arrow-large-right spark-icon-regular"
                            style={{
                                fontFamily: 'spark-icon',
                                fontSize: '20px',
                            }}
                        />
                    </Link>
                </>
            ),
        },
        {
            heading: 'Image Search by Text',
            icon: 'build-cube',
            tags: [
                {
                    text: 'Reference Implementation',
                    type: 'gray',
                },
                {
                    text: 'AI and Machine Learning',
                    type: 'gray',
                },
                {
                    type: 'gray',
                    text: 'Safety and Security',
                },
                { 
                    type: 'gray', 
                    text: 'Transportation' 
                },
            ],
            children: (
                <>
                    <Text style={{ color: '#161616' }}>
                        Multi-modal Large Vision Model-based reference
                        implementation. Retrieves image results based on user
                        queries from a local database (e.g. “Find pictures with
                        luggage”).
                    </Text>
                    <Link
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            height: '32px',
                            alignItems: 'flex-end',
                        }}
                        href='https://edgesoftwarecatalog.intel.com/details/?microserviceType=recipe&microserviceNameForUrl=metro-ai-suite-image-search-by-text'
                        target='_blank'
                    >
                        <i
                            className="spark-icon spark-icon-arrow-large-right spark-icon-regular"
                            style={{
                                fontFamily: 'spark-icon',
                                fontSize: '20px',
                            }}
                        />
                    </Link>
                </>
            ),
        },
        {
            heading: 'Visual Question Answering',
            icon: 'build-cube',
            tags: [
                {
                    text: 'Reference Implementation',
                    type: 'gray',
                },
                {
                    text: 'Generative AI',
                    type: 'gray',
                },
                {
                    type: 'gray',
                    text: 'Safety and Security',
                },
                { 
                    type: 'gray', 
                    text: 'Transportation' 
                },
            ],
            children: (
                <>
                    <Text style={{ color: '#161616' }}>
                        Gen AI-based reference implementation answers questions
                        based on images for image captioning and retrieval
                        use-cases (e.g. “Is there Luggage in the image”).
                    </Text>
                    <Link
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            height: '32px',
                            alignItems: 'flex-end',
                        }}
                        href='https://edgesoftwarecatalog.intel.com/details/?microserviceType=recipe&microserviceNameForUrl=metro-ai-suite-visual-question-answering'
                        target='_blank'
                    >
                        <i
                            className="spark-icon spark-icon-arrow-large-right spark-icon-regular"
                            style={{
                                fontFamily: 'spark-icon',
                                fontSize: '20px',
                            }}
                        />
                    </Link>
                </>
            ),
        },
        {
            heading: 'Interactive Digital Avatar',
            icon: 'build-cube',
            tags: [
                {
                    text: 'Reference Implementation',
                    type: 'gray',
                },
                {
                    text: 'Generative AI',
                    type: 'gray',
                },
                {
                    type: 'gray',
                    text: 'Safety and Security',
                },
                { 
                    type: 'gray', 
                    text: 'Transportation' 
                },
            ],
            children: (
                <>
                    <Text style={{ color: '#161616' }}>
                        A Windows-based demo leveraging LVM, Automatic Speech
                        Recognition (ASR), and Text-to-Speech (TTS) to
                        facilitate real-time voice interactions with customers.
                    </Text>
                    <Link
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            height: '32px',
                            alignItems: 'flex-end',
                        }}
                        href='https://edgesoftwarecatalog.intel.com/details/?microserviceType=recipe&microserviceNameForUrl=metro-ai-suite-interactive-digital-avatar'
                        target='_blank'
                    >
                        <i
                            className="spark-icon spark-icon-arrow-large-right spark-icon-regular"
                            style={{
                                fontFamily: 'spark-icon',
                                fontSize: '20px',
                            }}
                        />
                    </Link>
                </>
            ),
        },
        {
            heading: 'Video Processing Software Development Kit',
            icon: 'document-code',
            tags: [
                {
                    text: 'Software Development Kit',
                    type: 'gray',
                },
                {
                    text: 'Safety and Security',
                    type: 'gray',
                },
            ],
            children: (
                <>
                    <Text style={{ color: '#161616' }}>
                        Reference implementation and SDK that addresses video
                        processing acceleration needs for video-based solutions,
                        such as NVR and video matrix displays.
                    </Text>
                    <Link
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            height: '32px',
                            alignItems: 'flex-end',
                        }}
                        href='https://edgesoftwarecatalog.intel.com/details/?microserviceType=recipe&microserviceNameForUrl=metro-ai-suite-video-processing-software-development-kit'
                        target='_blank'
                    >
                        <i
                            className="spark-icon spark-icon-arrow-large-right spark-icon-regular"
                            style={{
                                fontFamily: 'spark-icon',
                                fontSize: '20px',
                            }}
                        />
                    </Link>
                </>
            ),
        },
        {
            heading: 'Edge Video Infrastructure: Image Search by Image',
            icon: 'build-cube',
            tags: [
                {
                    text: 'Reference Implementation',
                    type: 'gray',
                },
                {
                    type: 'gray',
                    text: 'Safety and Security',
                },
                { 
                    type: 'gray', 
                    text: 'Transportation' 
                },
            ],
            children: (
                <>
                    <Text style={{ color: '#161616' }}>
                        A high-end video processing solution, with feature
                        matching, inference, structuring, clustering, and
                        storage services. Includes a Traffic Search reference
                        implementation.
                    </Text>
                    <Link
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            height: '32px',
                            alignItems: 'flex-end',
                        }}
                        href='https://edgesoftwarecatalog.intel.com/details/?microserviceType=recipe&microserviceNameForUrl=metro-ai-suite-image-search-by-image-server'
                        target='_blank'
                    >
                        <i
                            className="spark-icon spark-icon-arrow-large-right spark-icon-regular"
                            style={{
                                fontFamily: 'spark-icon',
                                fontSize: '20px',
                            }}
                        />
                    </Link>
                </>
            ),
        },
    ];

    const TagArray = [
        { type: 'gray', text: 'Collection' },
        { type: 'gray', text: 'AI and Machine Learning' },
        { type: 'gray', text: 'Generative AI' },
        { type: 'gray', text: 'Safety and Security' },
        { type: 'gray', text: 'Transportation' },
    ];
    return (
        <main data-theme="tiber-g-10">
            {/* <Header style={{ position: 'initial' }} header-mode="black">
                <HeaderName href="#" prefix="">
                    Edge Platform
                </HeaderName>
            </Header> */}
            <Header />
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    // gap: '1rem',
                    paddingBlock: '1rem',
                }}
            >
                <Link
                    onClick={() => navigate('/')}
                    style={{ cursor: 'pointer', marginLeft: '8px' }}
                >
                    <span
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '.5rem',
                        }}
                    >
                        <i className="spark-icon spark-icon-regular spark-icon-chevron-left" />
                        Back
                    </span>
                </Link>
                <HeroBanner>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '1rem',
                        }}
                    >
                        <Heading style={{ color: 'white' }} size="m">
                            Intel® Tiber™ Metro AI Suite
                        </Heading>
                        <div style={{ display: 'flex', gap: '.5rem' }}>
                            <i
                                className="spark-icon spark-icon-regular spark-icon-folder"
                                style={{
                                    fontSize: '1.5rem',
                                    color: 'white',
                                }}
                            />
                            {TagArray.map((item, idx) => (
                                <Tag
                                    type={item.type}
                                    key={idx}
                                    style={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                    }}
                                >
                                    {item.text}
                                </Tag>
                            ))}
                        </div>
                    </div>
                </HeroBanner>
                <section
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '1rem',
                        padding: '2rem',
                        background: '#F9F9F9',
                    }}
                >
                    <Grid condensed style={{ display: 'flex', gap: '1rem' }}>
                        <Link
                            href="#overview"
                            style={getLinkStyle('#overview')}
                        >
                            Overview
                        </Link>
                        <Link
                            href="#in-this-collection"
                            style={getLinkStyle('#in-this-collection')}
                        >
                            In this Collection
                        </Link>
                    </Grid>

                    <Grid condensed id="overview" style={{ gap: '32px' }}>
                        <Column
                            lg={12}
                            md={6}
                            sm={4}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '2rem',
                            }}
                        >
                            <Heading size="m">Overview</Heading>
                            <p className="regular-Body-M-Subtitle-S-font-size-75">
                                The Intel® Tiber™ Metro AI Suite is an
                                application framework with libraries, tools, and
                                reference implementations, enabling partners to
                                create AI solutions in the Video Safety and
                                Security, Transportation, and Government Edge
                                markets.
                            </p>
                            <Heading size="s">Benefits</Heading>
                            {/* <label
                                style={{
                                    display: 'inline-block',
                                    marginBottom: '-1rem',
                                    color: 'var(--cds-text-secondary)'
                                }}
                            >
                                Bullet points should look like this:
                            </label> */}
                            <p className="regular-Body-L-Subtitle-M-font-size-100">
                                AI System qualification and co-marketing
                                opportunities for hardware builders.
                            </p>
                            <UnorderedList
                                style={{ color: 'var(--cds-text-secondary)' }}
                            >
                                <ListItem className="regular-Body-M-Subtitle-S-font-size-75">
                                    Demonstrate the capabilities of your Intel®
	                            AI System with the Intel® Tiber™ Metro AI Suite
	                            reference implementations.
                                </ListItem>
                                <ListItem className="regular-Body-M-Subtitle-S-font-size-75">
                                    Use the Intel® Tiber™ Metro AI Suite Device Qualification
	                            Tool to verify hardware performance and create system
	                            qualification reports.
                                </ListItem>
                                <ListItem className="regular-Body-M-Subtitle-S-font-size-75">
                                    Feature your qualified systems in{' '}
                                    <Link
                                        href="https://www.intel.com/content/www/us/en/developer/topic-technology/edge-5g/edge-solutions/hardware.html"
                                        target="_blank"
                                        style={{}}
                                    >
                                        Intel® Edge Software Recommended Hardware
                                    </Link>{' '}
                                    for co-marketing and ISV match-making
                                    opportunities.
                                </ListItem>
                            </UnorderedList>
                            <p className="regular-Body-L-Subtitle-M-font-size-100">
                                Accelerated development for solution builders.
                            </p>
                            <UnorderedList
                                style={{ color: 'var(--cds-text-secondary)' }}
                            >
                                <ListItem className="regular-Body-M-Subtitle-S-font-size-75">
                                    Get the Intel® Tiber™ Metro AI Suite Software Development
	                            Kit to use OpenVINO™ Toolkit, Intel® Deep Learning Streamer
	                            (Intel® DL Streamer), Intel® oneAPI and other tools, libraries,
	                            and microservices for media analytics and AI performance optimization.
                                </ListItem>
                                <ListItem className="regular-Body-M-Subtitle-S-font-size-75">
                                    Use reference implementations as blueprints
                                    for developing AI solutions optimized for
                                    Intel® hardware.
                                </ListItem>
                                <ListItem className="regular-Body-M-Subtitle-S-font-size-75">
                                    Promote your Intel® Tiber™ Metro AI Suite solutions in{' '}
                                    <Link
                                        href="https://networkbuilders.intel.com/communities/government/video-ai-cities"
                                        target="_blank"
                                    >
                                        Intel® Video & AI Cities
                                    </Link>{' '}
                                    to reach a broader audience.
                                </ListItem>
                            </UnorderedList>
                            <Heading size="s">Platforms Supported</Heading>
                            <p className="regular-Body-M-Subtitle-S-font-size-75">
                                The Intel® Tiber™ Metro AI Suite supports a variety of
	                        Intel® platforms, including Intel Atom®, Intel® Core™,
	                        Intel® Core™ Ultra, Intel® Xeon® and Intel® Arc™ GPUs
	                        for light to heavy AI workloads.
                            </p>
                        </Column>
                        <Column lg={4} md={2} sm={4}>
                            <div
                                style={{
                                    border: '1px solid var(--cds-border-subtle-01)',
                                }}
                            >
                                <Card style={{border:"none"}}>
                                    <section>
                                        <FormLabel style={{ fontSize: '14px' }}>
                                            Author
                                        </FormLabel>
                                        <h6 style={{ fontSize: '16px' }}>
                                            Intel
                                        </h6>
                                    </section>

                                    <section>
                                        <FormLabel style={{ fontSize: '14px' }}>
                                            Updated
                                        </FormLabel>
                                        <h6 style={{ fontSize: '16px' }}>
                                            12/26/2024
                                        </h6>
                                    </section>

                                    <section>
                                        <FormLabel style={{ fontSize: '14px' }}>
                                            Version
                                        </FormLabel>
                                        <h6 style={{ fontSize: '16px' }}>
                                            1.0
                                        </h6>
                                    </section>
                                </Card>
                                <h5
                                    style={{
                                        padding: '0.5rem 1rem',
                                        background: '#F4F5F5',
                                        borderTop: '2px solid var(--cds-border-subtle)',
                                        // borderBottom: '1px solid var(--cds-border-subtle-01)'
                                    }}
                                >
                                    Learn More
                                </h5>
                                <Card style={{border:'none'}}> 
                                    <Link
                                        href="https://www.intel.com/content/www/us/en/developer/topic-technology/edge-5g/edge-solutions/hardware.html"
                                        target="_blank"
                                    >
                                        <span
                                            style={{
                                                display: 'flex',
                                                alignItems: 'flex-end',
                                                gap: '.5rem',
                                            }}
                                        >
                                            <i
                                                className="spark-icon spark-icon-arrow-large-up-right spark-icon-regular"
                                                style={{
                                                    fontFamily: 'spark-icon',
                                                }}
                                            />
                                            <div className="external-link">
                                                Recommended Hardware Catalog
                                            </div>
                                        </span>
                                    </Link>
                                    <Link
                                        href="https://networkbuilders.intel.com/communities/government/video-ai-cities"
                                        target="_blank"
                                    >
                                        <span
                                            style={{
                                                display: 'flex',
                                                alignItems: 'flex-end',
                                                gap: '.5rem',
                                            }}
                                        >
                                            <i
                                                className="spark-icon spark-icon-arrow-large-up-right spark-icon-regular"
                                                style={{
                                                    fontFamily: 'spark-icon',
                                                }}
                                            />
                                            <div className="external-link">
                                                Video & AI Cities Solutions
                                                Catalog
                                            </div>
                                        </span>
                                    </Link>
                                </Card>
                            </div>
                        </Column>
                    </Grid>
                    <Grid
                        condensed
                        id="in-this-collection"
                        style={{ gap: '32px', marginTop: '40px' }}
                    >
                        <Column
                            lg={16}
                            md={8}
                            sm={4}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '2rem',
                            }}
                        >
                            <header
                                style={{
                                    display: 'flex',
                                    alignItems: 'flex-end',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Heading size="m">In this Collection</Heading>
                                <section
                                    style={{
                                        display: 'flex',
                                        alignItems: 'flex-end',
                                        gap: '1.25rem',
                                    }}
                                >
                                    <i
                                        onClick={() => setViewOption('list')}
                                        style={{
                                            color:
                                                viewOption === 'grid'
                                                    ? 'var(--icon-on-color-disabled)'
                                                    : '#0F9B8F',
                                            fontSize: '1.5rem',
                                            cursor: 'pointer',
                                        }}
                                        className="spark-icon spark-icon-regular spark-icon-list"
                                    />
                                    <i
                                        onClick={() => setViewOption('grid')}
                                        style={{
                                            color:
                                                viewOption === 'grid'
                                                    ? '#0F9B8F'
                                                    : 'var(--icon-on-color-disabled)',
                                            fontSize: '1.5rem',
                                            cursor: 'pointer',
                                        }}
                                        className="spark-icon spark-icon-regular spark-icon-grid"
                                    />
                                </section>
                            </header>
                            <p className="regular-Body-L-Subtitle-M-font-size-100">
                                Select from curated components, software tools,
                                reference implementations, and sample
                                applications.
                            </p>
                            <Grid
                                condensed
                                style={{ gap: '20px', marginRight: '54px' }}
                            >
                                {Cards.map((card, idx) => (
                                    <Column
                                        key={idx}
                                        lg={viewOption === 'grid' ? 4 : 16}
                                        md={viewOption === 'grid' ? 4 : 8}
                                        sm={4}
                                    >
                                        <Card
                                            heading={card.heading}
                                            icon={card.icon}
                                            iconGradient
                                            iconGradientColor="linear-gradient(
                                                                    180deg,
                                                                    #5400C0,
                                                                    #057aff,
                                                                    #16cfb1
                                                                )"
                                            children={card.children}
                                            tags={card.tags}
                                        />
                                    </Column>
                                ))}
                            </Grid>
                        </Column>
                    </Grid>
                </section>
            </div>
        </main>
    );
};
export default Collections;
