import PropTypes from 'prop-types'
import React from 'react'

const Icon = ({
    as = 'i',
    icon = 'picture',
    artworkStyle = 'regular',
    altText,
    style,
    color = 'primary',
    gradient,
    gradientColor,
    size,
    className,
    ...rest
}) => {
    const ElementTag = as
    const colorCheck = color ? { color: `var(--cds-spark-theme-icon-${color})` } : null
    const sizeCheck = size ? { fontSize: size } : null
    const gradientColorCheck = gradient
        ? {
            background: `${gradientColor ? gradientColor : '-webkit-linear-gradient(90deg, #eee, #8B50FF, #057AFF, #16CFB1)'}`,
            '-webkit-background-clip': 'text',
            '-webkit-text-fill-color': 'transparent'
        }
        : null

    const inlineStyles = {
        ...gradientColorCheck,
        ...colorCheck,
        ...sizeCheck,
        ...style
    }

    return (
        <ElementTag
            className={`spark-icon spark-icon-${icon} spark-icon-${artworkStyle} ${className}`}
            style={{
                fontFamily: 'spark-icon',
                ...inlineStyles
            }}
            alt={altText}
            {...rest}
        />
    )
}

Icon.propTypes = {
    icon: PropTypes.string.isRequired,
    artworkStyle: PropTypes.oneOf(['solid', 'regular', 'light']),
    size: PropTypes.string,
    color: PropTypes.oneOf(['primary', 'secondary', 'disabled', 'interactive', 'inverse']),
    altText: PropTypes.string,
    as: PropTypes.oneOf(['i', 'span']),
    className: PropTypes.string,
    style: PropTypes.object,
    gradient: PropTypes.bool,
    gradientColor: PropTypes.string
}
export default Icon