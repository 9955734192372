import React from 'react'

const Text = ({
    size = 'l',
    color = 'secondary',
    className,
    style,
    children,
    ...rest
}) => (
    <span
        className={`spark-text-size-${size} ${className}`}
        style={{ color: `var(--cds-spark-theme-text-${color})`, ...style }}
        {...rest}
    >
        {children}
    </span>
)

export default Text